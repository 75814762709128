.modal-content .addToCartModal .orderline-content .controls {
  text-align: center;
}
.modal-content .addToCartModal button {
  font-size: 14px;
  font-family: "Brown-Regular";
  text-transform: inherit;
}

.modal-content .addToCartModal button.outlined {
  background-color: white;
  color: black;
  border: 1px solid black;
  border-radius: 0%;
}

.modal-content .addToCartModal .orderline-row:last-of-type {
  border-bottom: 0px;
}

.modal-content .addToCartModal h2 {
  font-size: 24px;
}

.modal-content .addToCartModal h2.addTo {
  font-size: 20px;
}
.modal-content .addToCartModal .orderline-row p {
  font-family: "Brown-Light";
  text-transform: inherit;
  margin: 0px;
}

.modal-content .addToCartModal .free-shipping-progressbar small {
  font-family: "Brown-Regular";
  font-size: 12px;
}

.modal-content .addToCartModal .orderline-row p span.price-product {
  font-family: "Brown-Regular";
}
.modal-content .addToCartModal .orderline-row .controls {
  border: 0px;
  text-align: left;
  font-family: "Brown-Regular";
}
.modal-content .addToCartModal .orderline-content .controls span {
  width: 100%;
  margin-left: 0px;
}
.modal-content .addToCartModal .orderline-content .controls span::before {
  content: "x";
}
.modal-content .addToCartModal h2 {
  margin: 0px;
}
.modal-content .addToCartModal {
  margin: -10px;
}

.modal-content .addToCartModal .progressBar {
  padding: 20px 25px;
  background: #f8f8f8;
  margin: 0px -15px;
}

#ModalUpsellAddtocard .orderline-name {
  max-width: 150px;
}
.product-list-mobile {
  display: block;
}

.product-list-desktop {
  display: none;
}
#ModalUpsellAddtocard .variations-list-cart {
  display: none;
}
.modal-content .addToCartModal .glide__slides {
  padding-left: 0px !important;
}

.modal-content .addToCartModal .buttons div {
  margin-top: 10px;
}

@media (min-width: 767px) {
  .modal-content .addToCartModal .progressBar {
    padding: 20px 25px;
    background: #f8f8f8;
    margin: 0px;
  }
  .product-list-mobile {
    display: none;
  }

  .product-list-desktop {
    display: block;
  }

  .linesAndButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .linesAndButtons button {
    height: 40px;
    padding: 10px;
    line-height: 14px;
  }

  .modal-content .addToCartModal .buttons div {
    width: calc(100% - 10px);
    margin: 10px 5px;
  }
}
