.pagination {
  text-align: center;
}
.pagination ul {
  padding: 0;
  margin: 0;
}
.pagination li {
  display: inline-block;
  height: 40px;
  text-align: center;
  line-height: 40px;
  text-decoration: none;
  margin: 0px 5px;
  padding: 0 10px;
}

.pagination .clickable {
  cursor: pointer;
  border: 1px solid red;
}

.pagination .clickable:hover {
  color: #ff8172;
  border: 1px solid #ff8172;
}
